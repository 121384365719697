<template>
    <div class="warp">
        <div class="pass-through">
            <p class="title">合作银行</p>
            <p class="desc">Mi Pay与中国银联及各大银行合作，正不断接入更多的银行，如果你的银行卡无法使用，请联系相关银行</p>
            <div class="banks">
                <ul>
                    <li>
                        <span>合作银行</span>
                        <span>信用卡</span>
                        <span>借记卡</span>
                    </li>
                    <li>
                        <span>合作银行</span>
                        <span>信用卡</span>
                        <span>借记卡</span>
                    </li>
                    <li v-for="(bank, index) in bankList" :key="index">
                        <span>{{bank.bankName}}</span>
                        <span v-show="bank.creditSupported"><img src="https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/banks/check.png" alt=""></span>
                        <span v-show="bank.debitSupported"><img src="https://cdn.cnbj1.fds.api.mi-img.com/mipay-portal/page-images/banks/check.png" alt=""></span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    export default {
        name: 'Home',
        data () {
            return {
                bankList: []
            }
        },
        mounted () {
            axios.get('https://sf.pay.xiaomi.com/issuers/banks/supportedlist').then(res => {
                this.bankList = res.data.data
            }).catch(err => {
                console.log(err)
            })
        },
    }
</script>

<style scoped>
.warp .title{
    text-align: center;
    margin: 5vw 0 .5vw;
    font-size: 2vw;
    font-weight: 400;
}
.warp .desc{
    text-align: center;
    font-size: .9vw;
    color: #666;
}
.banks ul{
    width: 70%;
    margin: 4vw auto;
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    display: flex;
    flex-wrap: wrap;
}
.banks ul li{
    width: 50%;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    line-height: 4.5vw;
    font-size: 1vw;
}
.banks ul li span{
    color: #3c3c3c;
    display: inline-block;
    text-align: center;
}
.banks ul li span:first-child{
    width: 55%;
}
.banks ul li span:nth-child(2){
    width: 20%;
}
.banks ul li span:last-child{
    width: 20%;
}
.banks ul li span img{
    position: relative;
    top: 0.7vw;
}
</style>